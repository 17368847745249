import MFab, { FabProps } from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles'
import cx from 'classnames'

export type Props = FabProps & {
  className?: string
  textColor?: string
  bgcolor?: string
}

type StyleProps = {
  textColor?: Props['textColor']
  bgcolor?: Props['bgcolor']
}

const useStyles = makeStyles({
  color: {
    color: (props: StyleProps) => (props.textColor ? props.textColor : 'inherit'),
  },
  bgcolor: {
    backgroundColor: (props: StyleProps) => (props.bgcolor ? props.bgcolor : 'inherit'),
    '&:hover': {
      backgroundColor: (props: StyleProps) => (props.bgcolor ? lighten(props.bgcolor, 0.2) : 'inherit'),
    },
  },
})

const Fab = ({ className, textColor, bgcolor, ...props }: Props) => {
  const classes = useStyles({ textColor, bgcolor })

  return <MFab className={cx(textColor && classes.color, bgcolor && classes.bgcolor, className)} {...props} />
}

export default Fab
