import cx from 'classnames'
import React, { ComponentProps } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MTooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import Box from '@base/Box'
import Typography from '@base/Typography'

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: 'transparent',
  },
  popper: {
    opacity: 1,
  },
})

const defaultStyle = {
  bgcolor: '#ccc',
  px: 0.75,
  py: 0.25,
  mt: -1.5,
  borderRadius: 4,
}

type Props = TooltipProps &
  ComponentProps<typeof Typography> & {
    boxStyle?: ComponentProps<typeof Box>
  }

const Tooltip = ({
  children,
  title,
  variant,
  weight,
  placement,
  enterDelay,
  boxStyle = defaultStyle,
  classes = {},
  ...props
}: Props) => {
  const ownClasses = useStyles({})

  return (
    <MTooltip
      placement={placement || 'top-start'}
      enterTouchDelay={1}
      enterDelay={enterDelay || 0} // default is 200
      title={
        typeof title === 'string' ? (
          <Box borderRadius={32} {...boxStyle}>
            <Typography variant={variant || 'caption'} weight={weight} color='textPrimary'>
              {title}
            </Typography>
          </Box>
        ) : (
          title
        )
      }
      classes={{
        ...classes,
        tooltip: cx(ownClasses.tooltip, classes.tooltip),
        popper: cx(ownClasses.popper, classes.popper),
      }}
      {...props}
    >
      {children}
    </MTooltip>
  )
}

export default Tooltip
