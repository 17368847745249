import getConfig from "next/config";

import { GetTherapistsByQuizQuery, Status, TutorItemFragment, TutorPriceType } from "@gql/__generated__";
import { makePrice, Price } from "@helpers/price";
import { seconds } from "@helpers/interval";

const { CALL_UNIT_LENGTH } = getConfig().publicRuntimeConfig

type TutorPriceWithAmount = {
  __typename: 'TutorPrice'
  type: TutorPriceType
  price: number
}

export type TutorRate = {
  price: Price
  duration: number
}

const getPriceByType = <TutorPrice extends TutorPriceWithAmount>(
  prices: TutorPrice[],
  type: TutorPriceType,
): TutorPrice => {
  const foundPrices = prices.filter(p => p.type === type)

  if (!foundPrices.length) {
    throw new Error(`Tutor price of type '${type}' not found`)
  }

  if (foundPrices.length !== 1) {
    throw new Error(`Found multiple tutor prices of type '${type}'. Expected exactly one.`)
  }

  return foundPrices[0]
}

const callUnitDuration = seconds(CALL_UNIT_LENGTH)

export const getRateByType = <TutorPrice extends TutorPriceWithAmount>(
  prices: TutorPrice[],
  type: TutorPriceType,
  duration: number = callUnitDuration,
): TutorRate => {
  const price = getPriceByType(prices, type)

  return {
    price: makePrice(price.price * (duration / 1000)),
    duration,
  }
}

export const hasTutorId = (profileId: string) => profileId && profileId.startsWith('TU')

export const TutorItemFactory = (pickedTherapist: GetTherapistsByQuizQuery['tutors'][number]): TutorItemFragment => {
  const { id, userId, firstName, lastName, displayName, image, introduction, motto, slug, topic } = pickedTherapist
  return {
    __typename: 'Tutor',
    id,
    intros: [{
      __typename: 'TutorIntro',
      language: 'cs',
      introduction,
      motto
    }],
    slug,
    user: {
      __typename: 'User',
      id: userId,
      displayName,
      firstName,
      lastName,
      image,
      currentStatus: {
        status: Status.Online,
        __typename: 'CurrentStatus',
        text: null,
      },
      countryCode: 'CZE',
    },
    topics: [
      {
        __typename: 'Topic',
        name: topic,
      },
    ],
  }
}
