import React, { ComponentProps, ReactNode } from 'react'
import { Omit, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@base/Box'
import Typography from '@base/Typography'

const useStyles = makeStyles<Theme, StyleProps>({
  hint: props => ({
    position: 'relative',
    lineHeight: 1,

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -5,
      left: '50%',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 5px 5px 5px',
      borderColor: `transparent transparent ${props.bgcolor} transparent`,
    },
  }),
})

type StyleProps = {
  bgcolor: string
}

type Props = {
  children: ReactNode
  boxStyle?: ComponentProps<typeof Box>
  textStyle?: Omit<ComponentProps<typeof Typography>, 'children'>
}

const Bubble = ({ children, boxStyle, textStyle }: Props) => {
  const { bgcolor = '#E4E7EB', ...boxProps } = boxStyle || {}
  const classes = useStyles({ bgcolor })

  return (
    <Box p={1} bgcolor={bgcolor} borderRadius={4} className={classes.hint} {...boxProps}>
      <Typography variant='caption' color='textPrimary' {...textStyle}>
        {children}
      </Typography>
    </Box>
  )
}

export default Bubble
